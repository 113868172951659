import React, { Component, Suspense, lazy } from "react";
import { Switch, Route, Redirect } from "react-router-dom";

import Spinner from "../app/shared/Spinner";

const Dashboard = lazy(() => import("./dashboard/Dashboard"));

const MyOrganization = lazy(() => import("./organization/MyOrganization"));

const EditProfile = lazy(() => import("./organization/EditProfile"));

const OrgAdminsList = lazy(() => import("./orgadmins/OrgAdminsList"));

const MyTeamList = lazy(() => import("./team/MyTeamList"));

const ServiceHistory = lazy(() => import("./team/ServiceHistory"));

const ConsumerServiceHistory = lazy(() => import("./team/ConsumerServiceHistory"));

const CampaignsList = lazy(() => import("./campaigns/CampaignsList"));

const CampaignDetails = lazy(() => import("./campaigns/CampaignDetails"));

const CampaignSignups = lazy(() => import("./campaigns/CampaignSignups"));

const CampaignReviews = lazy(() => import("./campaigns/CampaignReviews"));

const MyReports = lazy(() => import("./reports/MyReports"));

const StripeSuccessScreen = lazy(() => import("./payments/StripeSuccessScreen"));

const StripeFailureScreen = lazy(() => import("./payments/StripeFailureScreen"));

class AppRoutes extends Component {
    render() {
        return (
            <Suspense fallback={<Spinner />}>
                <Switch>
                    <Route exact path="/dashboard" component={Dashboard} />

                    <Route path="/organization/myorganization" component={MyOrganization} />

                    <Route path="/organization/editProfile" component={EditProfile} />

                    <Route path="/orgadmins" component={OrgAdminsList} />

                    <Route path="/team/myteamlist" component={MyTeamList} />

                    <Route path="/team/serviceHistory" component={ServiceHistory} />

                    <Route path="/team/consumer/serviceHistory" component={ConsumerServiceHistory} />

                    <Route path="/campaigns/campaignslist" component={CampaignsList} />

                    <Route path="/campaigns/campaigndetails" component={CampaignDetails} />

                    <Route path="/campaigns/campaignreviews" component={CampaignReviews} />

                    <Route path="/campaigns/campaignsignups" component={CampaignSignups} />

                    <Route path="/reports/myreports" component={MyReports} />

                    <Route path="/payments/stripesuccess" component={StripeSuccessScreen} />

                    <Route path="/payments/stripefailure" component={StripeFailureScreen} />

                    <Redirect to="/dashboard" />
                </Switch>
            </Suspense>
        );
    }
}

export default AppRoutes;
