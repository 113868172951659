import axios from "axios";

import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
import { ENV, validateResponse, validateErrorResponse } from "../config/Env";
toast.configure({
    title: "Gen3",
    autoClose: 5000,
});

//const base_url = "http://localhost:8081/";

export function getUserDetails() {
    let user_details_url = ENV.module_user_url + "org/login";
    return axios({
        method: "post",
        url: user_details_url,
        headers: {
            Authorization: "Bearer " + window.localStorage.authToken,
        },
    })
        .then((response) => {
            validateResponse(response);
            return response;
        })
        .catch((error) => {
            validateErrorResponse(error);
            return error;
        });
}

export function userSignup(userSignupReq) {
    let userSignup_url = ENV.module_user_url + "org/signup";
    return axios({
        method: "post",
        url: userSignup_url,
        data: userSignupReq,
        headers: {
            Authorization: "Bearer " + window.localStorage.authToken,
        },
    })
        .then((response) => {
            console.log("Signup response  " + JSON.stringify(response));
            validateResponse(response);
            return response;
        })
        .catch((error) => {
            return error;
        });
}

export function getOrganization(organizationId) {
    let get_org_url = ENV.module_user_url + "org/";

    return axios({
        method: "GET",
        url: get_org_url,
        headers: {
            Authorization: "Bearer " + window.localStorage.authToken,
        },
    })
        .then((response) => {
            validateResponse(response);
            return response;
        })
        .catch((error) => {
            validateErrorResponse(error);
            return error;
        });
}

export function updateOrganization(updateOrgReq) {
    let update_org_url = ENV.module_user_url + "org/";
    return axios({
        method: "PUT",
        data: updateOrgReq,
        url: update_org_url,
        headers: {
            Authorization: "Bearer " + window.localStorage.authToken,
        },
    })
        .then((response) => {
            validateResponse(response);
            return response;
        })
        .catch((error) => {
            validateErrorResponse(error);
            return error;
        });
}

export function getOrgAdmins(pagenumber, pagesize, search) {

    if (pagesize === undefined) {
        pagesize = 10;
    }

    let get_org_admins_url =
        ENV.module_user_url + "org/admins?pn=" + pagenumber + "&ps=" + pagesize + "&search=" + search;
    return axios({
        method: "GET",
        url: get_org_admins_url,
        headers: {
            Authorization: "Bearer " + window.localStorage.authToken,
        },
    })
        .then((response) => {
            validateResponse(response);
            return response;
        })
        .catch((error) => {
            validateErrorResponse(error);
            return error;
        });
}

export function changeAdminRole(adminId, adminrole) {
    let get_org_change_admin_role_url = ENV.module_user_url + "org/admins/" + adminId + "/role/" + adminrole;
    return axios({
        method: "PUT",
        url: get_org_change_admin_role_url,
        headers: {
            Authorization: "Bearer " + window.localStorage.authToken,
        },
    })
        .then((response) => {
            validateResponse(response);
            return response;
        })
        .catch((error) => {
            validateErrorResponse(error);
            return error;
        });
}

export function getOrgMembers(status, userType, pn, ps) {
    let get_org_members_url =
        ENV.module_user_url + "org/members?status=" + status + "&userType=" + userType + "&pn=" + pn + "&ps=" + ps;
    return axios({
        method: "GET",
        url: get_org_members_url,
        headers: {
            Authorization: "Bearer " + window.localStorage.authToken,
        },
    })
        .then((response) => {
            validateResponse(response);
            return response;
        })
        .catch((error) => {
            validateErrorResponse(error);
            return error;
        });
}

export function acceptOrgProvider(memberId) {
    let get_org_accept_team_url = ENV.module_user_url + "org/members/" + memberId + "/accept";
    return axios({
        method: "PUT",
        url: get_org_accept_team_url,
        headers: {
            Authorization: "Bearer " + window.localStorage.authToken,
        },
    })
        .then((response) => {
            validateResponse(response);
            return response;
        })
        .catch((error) => {
            validateErrorResponse(error);
            return error;
        });
}

export function rejectOrgProvider(memberId) {
    let get_org_reject_team_url = ENV.module_user_url + "org/members/" + memberId + "/reject";
    return axios({
        method: "PUT",
        url: get_org_reject_team_url,
        headers: {
            Authorization: "Bearer " + window.localStorage.authToken,
        },
    })
        .then((response) => {
            validateResponse(response);
            return response;
        })
        .catch((error) => {
            validateErrorResponse(error);
            return error;
        });
}

export function getOrgMemberStatusTypes() {
    let get_status_types_url = ENV.module_user_url + "metadata/org/join/status";

    return axios({
        method: "GET",
        url: get_status_types_url,
        headers: {
            Authorization: "Bearer " + window.localStorage.authToken,
        },
    })
        .then((response) => {
            validateResponse(response);
            return response;
        })
        .catch((error) => {
            validateErrorResponse(error);
            return error;
        });
}

export function getOrganizationTypes() {
    let get_org_types_url = ENV.module_user_url + "metadata/orgtypes";

    return axios({
        method: "GET",
        url: get_org_types_url,
        headers: {
            Authorization: "Bearer " + window.localStorage.authToken,
        },
    })
        .then((response) => {
            validateResponse(response);
            return response;
        })
        .catch((error) => {
            validateErrorResponse(error);
            return error;
        });
}

export function makeAdmin(memberId) {
    let get_org_make_admin_url = ENV.module_user_url + "org/members/" + memberId + "/admin";
    return axios({
        method: "PUT",
        url: get_org_make_admin_url,
        headers: {
            Authorization: "Bearer " + window.localStorage.authToken,
        },
    })
        .then((response) => {
            validateResponse(response);
            return response;
        })
        .catch((error) => {
            validateErrorResponse(error);
            return error;
        });
}
//{{user_app_url}}/org/admins/{{orgadmin_id}}
export function removeOrgAdmin(adminId) {
    let remove_org_admin_url = ENV.module_user_url + "org/admins/" + adminId;
    return axios({
        method: "DELETE",
        url: remove_org_admin_url,
        headers: {
            Authorization: "Bearer " + window.localStorage.authToken,
        },
    })
        .then((response) => {
            validateResponse(response);
            return response;
        })
        .catch((error) => {
            validateErrorResponse(error);
            return error;
        });
}

export function uploadImage(adminId, fileData) {
    let upload_image_url = ENV.module_user_url + "org/admin/" + adminId + "/imageupload";
    return axios({
        method: "POST",
        data: fileData,
        url: upload_image_url,
        headers: {
            Authorization: "Bearer " + window.localStorage.authToken,
        },
    })
        .then((response) => {
            validateResponse(response);
            return response;
        })
        .catch((error) => {
            validateErrorResponse(error);
            return error;
        });
}

export function getLanguagesTypes() {
    let get_lang_types_url = ENV.module_user_url + "metadata/preferred/language";

    return axios({
        method: "GET",
        url: get_lang_types_url,
        headers: { Authorization: "Bearer " + window.localStorage.authToken },
    })
        .then((response) => {
            validateResponse(response);
            return response;
        })
        .catch((error) => {
            validateErrorResponse(error);
            return error;
        });
}
export function getGenderTypes() {
    let get_gender_types_url = ENV.module_user_url + "metadata/gender";

    return axios({
        method: "GET",
        url: get_gender_types_url,
        headers: { Authorization: "Bearer " + window.localStorage.authToken },
    })
        .then((response) => {
            validateResponse(response);
            return response;
        })
        .catch((error) => {
            validateErrorResponse(error);
            return error;
        });
}
export function getProvider(provider_Id) {
    let get_provider_url = ENV.module_user_url + "org/admin/" + provider_Id + "/profile";

    return axios({
        method: "GET",
        url: get_provider_url,
        headers: { Authorization: "Bearer " + window.localStorage.authToken },
    })
        .then((response) => {
            validateResponse(response);
            return response;
        })
        .catch((error) => {
            validateErrorResponse(error);
            return error;
        });
}
export function updateProvider(provider_Id, updateProviderReq) {
    let update_provider_url = ENV.module_user_url + "org/admin/" + provider_Id + "/profile";

    return axios({
        method: "PUT",
        data: updateProviderReq,
        url: update_provider_url,
        headers: { Authorization: "Bearer " + window.localStorage.authToken },
    })
        .then((response) => {
            validateResponse(response);
            return response;
        })
        .catch((error) => {
            validateErrorResponse(error);
            return error;
        });
}

export function getActivePaymentOrganizations(pagenumber, pagesize, search) {

    if (pagesize === undefined) {
        pagesize = 10;
    }

    let get_active_org_url =
        ENV.module_user_url + "org/linked/payments?pn=" + pagenumber + "&ps=" + pagesize + "&search=" + search;
    return axios({
        method: "GET",
        url: get_active_org_url,
        headers: {
            Authorization: "Bearer " + window.localStorage.authToken,
        },
    })
        .then((response) => {
            validateResponse(response);
            return response;
        })
        .catch((error) => {
            validateErrorResponse(error);
            return error;
        });
}

export function addPayAccount() {
    let add_pay_account_url = ENV.module_user_url + "org/payaccount/add";
    return axios({
        method: "POST",
        url: add_pay_account_url,
        headers: {
            Authorization: "Bearer " + window.localStorage.authToken,
        },
    })
        .then((response) => {
            validateResponse(response);
            return response;
        })
        .catch((error) => {
            validateErrorResponse(error);
            return error;
        });
}

export function updatePayAccount() {
    let update_pay_account_url = ENV.module_user_url + "org/payaccount/update";
    return axios({
        method: "POST",
        url: update_pay_account_url,
        headers: {
            Authorization: "Bearer " + window.localStorage.authToken,
        },
    })
        .then((response) => {
            validateResponse(response);
            return response;
        })
        .catch((error) => {
            validateErrorResponse(error);
            return error;
        });
}

export function getNewSignupLink() {
    let get_new_signup_link_url = ENV.module_user_url + "org/payaccount/newlink";
    return axios({
        method: "POST",
        url: get_new_signup_link_url,
        headers: {
            Authorization: "Bearer " + window.localStorage.authToken,
        },
    })
        .then((response) => {
            validateResponse(response);
            return response;
        })
        .catch((error) => {
            validateErrorResponse(error);
            return error;
        });
}

export function removePayAccount() {
    let remove_pay_account_url = ENV.module_user_url + "org/payaccount";
    return axios({
        method: "DELETE",
        url: remove_pay_account_url,
        headers: {
            Authorization: "Bearer " + window.localStorage.authToken,
        },
    })
        .then((response) => {
            validateResponse(response);
            return response;
        })
        .catch((error) => {
            validateErrorResponse(error);
            return error;
        });
}

export function linkOrgPaymentAccount(orgId) {
    let link_pay_account_url = ENV.module_user_url + "org/payaccount/link/" + orgId;

    return axios({
        method: "POST",
        url: link_pay_account_url,
        headers: { Authorization: "Bearer " + window.localStorage.authToken },
    })
        .then((response) => {
            validateResponse(response);
            return response;
        })
        .catch((error) => {
            validateErrorResponse(error);
            return error;
        });
}

export function getOrgUserTypes() {
    let get_org_user_types_url = ENV.module_user_url + "metadata/orgusertypes";

    return axios({
        method: "GET",
        url: get_org_user_types_url,
        headers: {
            Authorization: "Bearer " + window.localStorage.authToken,
        },
    })
        .then((response) => {
            validateResponse(response);
            return response;
        })
        .catch((error) => {
            validateErrorResponse(error);
            return error;
        });
}
