import React, { Component } from "react";
import { withRouter, Link } from "react-router-dom";
import { Form } from "react-bootstrap";
import firebase from "firebase";
import { userSignup, getOrganizationTypes } from "../services/OrganizationServices";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export class Register extends Component {
    constructor(props) {
        super(props);
        this.state = {
            orgTypes: [],

            name: "",
            website: "",
            contactNumber: "",
            contactPerson: "",
            shortCode: "",
            organizationType: "",
            facebookUrl: "",
            twitterUrl: "",
            accept_terms_and_conditions: false,
            accept_privacy_policy: false,

            address: {
                area: "",
                street: "",
                city: "",
                state: "",
                zipcode: "",
                country: "",
                latitude: "",
                longitude: "",
            },

            admin: {
                firstName: "",
                lastName: "",
                email: "",
                contactNumber: "",
            },
        };

        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(e) {
        if (e.target.id === "name") {
            this.setState({
                name: e.target.value,
            });
        } else if (e.target.id === "shortCode") {
            this.setState({
                shortCode: e.target.value,
            });
        } else if (e.target.id === "website") {
            this.setState({
                website: e.target.value,
            });
        } else if (e.target.id === "contactNumber") {
            this.setState({
                contactNumber: e.target.value,
            });
        } else if (e.target.id === "contactPerson") {
            this.setState({
                contactPerson: e.target.value,
            });
        } else if (e.target.id === "organizationType") {
            this.setState({
                organizationType: e.target.value,
            });
        } else if (e.target.id === "facebookUrl") {
            this.setState({
                facebookUrl: e.target.value,
            });
        } else if (e.target.id === "twitterUrl") {
            this.setState({
                twitterUrl: e.target.value,
            });
        } else if (e.target.id === "address.area") {
            this.setState({
                area: e.target.value,
            });
        } else if (e.target.id === "address.street") {
            this.setState({
                street: e.target.value,
            });
        } else if (e.target.id === "address.city") {
            this.setState({
                city: e.target.value,
            });
        } else if (e.target.id === "address.state") {
            this.setState({
                state: e.target.value,
            });
        } else if (e.target.id === "address.zipcode") {
            this.setState({
                zipcode: e.target.value,
            });
        } else if (e.target.id === "address.country") {
            this.setState({
                country: e.target.value,
            });
        } else if (e.target.id === "address.latitude") {
            this.setState({
                latitude: e.target.value,
            });
        } else if (e.target.id === "address.longitude") {
            this.setState({
                longitude: e.target.value,
            });
        } else if (e.target.id === "admin.firstName") {
            this.setState({
                firstName: e.target.value,
            });
        } else if (e.target.id === "admin.lastName") {
            this.setState({
                lastName: e.target.value,
            });
        } else if (e.target.id === "admin.email") {
            this.setState({
                email: e.target.value,
            });
        } else if (e.target.id === "admin.contactNumber") {
            this.setState({
                contactNumber: e.target.value,
            });
        }
    }

    componentDidMount() {
        //   const userData = JSON.parse(localStorage.getItem("loggedinuser"));
        //   this.state.adminId = userData.userId;
        this.getOrganizationTypes();
    }

    onChangedCheckbox = (e) => {
        if (e.target.type === "checkbox") {
            this.setState({ [e.target.name]: e.target.checked });
        } else this.setState({ [e.target.name]: e.target.value });
    };

    signinhandleClick = () => {
        window.localStorage.setItem("screen", "signin");
        let path = "/user-pages/Login";
        this.props.history.push(path);
        firebase
            .auth()
            .signOut()
            .then(function () {
                window.localStorage.removeItem("user");
                window.localStorage.removeItem("screen");
                window.location.href = "/";
                console.log("Successufully signed out");
            })
            .catch(function () {
                console.log("Error signing out");
            });
    };
    /*Org Signup on Sumbit*/
    onSubmit(e) {
        e.preventDefault();
        this.userSignUp();
    }

    userSignUp(e) {
        if (this.state.accept_terms_and_conditions === false) {
            toast.error("Please accept terms and conditions");
        } else if (this.state.accept_privacy_policy === false) {
            toast.error("Please accept privacy policy");
        }

        const orgSignupReq = {
            name: this.state.name,
            website: this.state.website,
            contactNumber: this.state.contactNumber,
            contactPerson: this.state.contactPerson,
            shortCode: this.state.shortCode,
            organizationType: this.state.organizationType,
            facebookUrl: this.state.facebookUrl,
            twitterUrl: this.state.twitterUrl,
            accept_terms_and_conditions: this.state.accept_terms_and_conditions,
            accept_privacy_policy: this.state.accept_privacy_policy,

            address: {
                area: this.state.area,
                street: this.state.street,
                city: this.state.city,
                state: this.state.state,
                zipcode: this.state.zipcode,
                country: this.state.country,
                latitude: this.state.latitude,
                longitude: this.state.longitude,
            },
            admin: {
                firstName: this.state.firstName,
                lastName: this.state.lastName,
                email: this.state.email,
                contactNumber: this.state.contactNumber,
            },
        };
        userSignup(orgSignupReq).then((response) => {
            try {
                if (response.message != null) {
                    if (response.data.error_message !== "") {
                        // $('#signupErrorDiv').show();
                        // $('#signupError').text(response.message);
                        // $('#registerButton').text('Register');
                        // $('#registerButton').removeAttr('disabled');
                    }
                } else {
                    var userDetails = response.data;
                    toast.success("Account created successfully");
                    window.localStorage.setItem("org_id", userDetails.id);
                    window.localStorage.setItem("user", "TEST");
                    window.localStorage.removeItem("screen");
                    window.location.href = "/";
                }
            } catch (err) {
                // $('#signupErrorDiv').show();
                // $('#signupError').text(err.message);
                // $('#registerButton').text('Register');
                // $('#registerButton').removeAttr('disabled');
            }
        });
    }

    getOrganizationTypes() {
        getOrganizationTypes().then((response) => {
            if (response.data !== undefined && response.data !== "") {
                this.setState({
                    orgTypes: response.data.types,
                    organizationType: response.data.types[3],
                });
            }
        });
    }

    render() {
        const { accept_terms_and_conditions, accept_privacy_policy } = this.state;

        return (
            <div>
                <div className="d-flex align-items-center auth px-0">
                    <div className="row w-100 mx-0">
                        <div className="col-lg-6 mx-auto">
                            <div className="auth-form-light text-left py-5 px-4 px-sm-5">
                                <div className="text-center">
                                    <h4>Register Organization</h4>
                                </div>
                                <div className="validationError displayHide" id="signupErrorDiv">
                                    <label id="signupError"></label>
                                </div>
                                <Form className="pt-3" onSubmit={this.onSubmit.bind(this)}>
                                    <div className="row mb-3">
                                        <div className="col-md-6">
                                            <Form.Group>
                                                <label htmlFor="name">
                                                    Organization Name <span className="required-star"></span>
                                                </label>
                                                <Form.Control
                                                    type="text"
                                                    size="lg"
                                                    className="h-auto"
                                                    id="name"
                                                    onChange={this.handleChange}
                                                    required
                                                />
                                            </Form.Group>
                                        </div>

                                        <div className="col-md-6">
                                            <Form.Group>
                                                <label htmlFor="organizationType">
                                                    Organization Type <span className="required-star"></span>
                                                </label>

                                                <select
                                                    className="form-control"
                                                    id="organizationType"
                                                    value={this.state.organizationType}
                                                    onChange={this.handleChange}
                                                    style={{ color: "black" }}
                                                >
                                                    {this.state.orgTypes.map((orgType) => {
                                                        return <option key={orgType}>{orgType}</option>;
                                                    })}
                                                </select>
                                            </Form.Group>
                                        </div>


                                        {/* <div className="col-md-6">
                                            <Form.Group>
                                                <label htmlFor="shortCode">Short Code Name</label>
                                                <Form.Control
                                                    type="text"
                                                    className="h-auto"
                                                    size="lg"
                                                    id="shortCode"
                                                    onChange={this.handleChange}
                                                />
                                            </Form.Group>
                                        </div> */}
                                    </div>
                                    <div className="row mb-3">
                                        <div className="col-md-6">
                                            <Form.Group>
                                                <label htmlFor="website">
                                                    Website Url<span className="required-star"></span>
                                                </label>
                                                <Form.Control
                                                    type="text"
                                                    size="lg"
                                                    className="h-auto"
                                                    id="website"
                                                    onChange={this.handleChange}
                                                    required
                                                />
                                            </Form.Group>
                                        </div>
                                        <div className="col-md-6">
                                            <Form.Group>
                                                <label htmlFor="contactNumber">
                                                    Main Phone Number <span className="required-star"></span>
                                                </label>
                                                <Form.Control
                                                    type="text"
                                                    size="lg"
                                                    className="h-auto"
                                                    id="contactNumber"
                                                    onChange={this.handleChange}
                                                    required
                                                />
                                            </Form.Group>
                                        </div>
                                    </div>
                                    <div className="row mb-3">
                                        <div className="col-md-6">
                                            <Form.Group>
                                                <label htmlFor="facebookUrl">Facebook Url</label>
                                                <Form.Control
                                                    type="text"
                                                    size="lg"
                                                    className="h-auto"
                                                    id="facebookUrl"
                                                    onChange={this.handleChange}
                                                />
                                            </Form.Group>
                                        </div>
                                        <div className="col-md-6">
                                            <Form.Group>
                                                <label htmlFor="twitterUrl">Twitter Url</label>
                                                <Form.Control
                                                    type="text"
                                                    size="lg"
                                                    className="h-auto"
                                                    id="twitterUrl"
                                                    onChange={this.handleChange}
                                                />
                                            </Form.Group>
                                        </div>
                                    </div>
                                    {/* <div className="row mb-3">
                                        <div className="col-md-6">
                                            <Form.Group>
                                                <label htmlFor="contactPerson">Contact Person</label>
                                                <Form.Control
                                                    type="text"
                                                    className="form-control"
                                                    id="contactPerson"
                                                    onChange={this.handleChange}
                                                />
                                            </Form.Group>
                                        </div>
                                        <div className="col-md-6">
                                            <Form.Group>
                                                <label htmlFor="organizationType">
                                                    Organization Type <span className="required-star"></span>
                                                </label>

                                                <select
                                                    className="form-control"
                                                    id="organizationType"
                                                    value={this.state.orgTypes[3]}
                                                    onChange={this.handleChange}
                                                    style={{ color: "black" }}
                                                >
                                                    {this.state.orgTypes.map((orgType) => {
                                                        return <option key={orgType}>{orgType}</option>;
                                                    })}
                                                </select>
                                            </Form.Group>
                                        </div>
                                    </div> */}

                                    <p className="card-description" style={{ fontSize: '18px', fontWeight: 'bold', marginTop: '24px' }}> Address </p>
                                    <div className="row mb-3">
                                        {/* <div className="col-md-6">
                                            <Form.Group>
                                                <label htmlFor="address.area">Area</label>
                                                <Form.Control
                                                    type="text"
                                                    className="h-auto"
                                                    size="lg"
                                                    id="address.area"
                                                    onChange={this.handleChange}
                                                />
                                            </Form.Group>
                                        </div> */}
                                        <div className="col-md-12" >
                                            <Form.Group>
                                                <label htmlFor="address.street">Street</label>
                                                <Form.Control
                                                    type="text"
                                                    className="h-auto"
                                                    size="lg"
                                                    id="address.street"
                                                    onChange={this.handleChange}
                                                />
                                            </Form.Group>
                                        </div>
                                    </div>
                                    <div className="row mb-3">
                                        <div className="col-md-6">
                                            <Form.Group>
                                                <label htmlFor="address.city">City</label>
                                                <Form.Control
                                                    type="text"
                                                    className="h-auto"
                                                    size="lg"
                                                    id="address.city"
                                                    onChange={this.handleChange}
                                                />
                                            </Form.Group>
                                        </div>
                                        <div className="col-md-6">
                                            <Form.Group>
                                                <label htmlFor="address.state">State</label>
                                                <Form.Control
                                                    type="text"
                                                    className="h-auto"
                                                    size="lg"
                                                    id="address.state"
                                                    onChange={this.handleChange}
                                                />
                                            </Form.Group>
                                        </div>
                                    </div>
                                    <div className="row mb-3">
                                        <div className="col-md-6">
                                            <Form.Group>
                                                <label htmlFor="address.zipcode">Zipcode</label>
                                                <Form.Control
                                                    type="text"
                                                    className="h-auto"
                                                    size="lg"
                                                    id="address.zipcode"
                                                    onChange={this.handleChange}
                                                />
                                            </Form.Group>
                                        </div>
                                        {/* <div className="col-md-6">
                                            <Form.Group>
                                                <label htmlFor="address.country">Country</label>
                                                <Form.Control
                                                    type="text"
                                                    className="h-auto"
                                                    size="lg"
                                                    id="address.country"
                                                    onChange={this.handleChange}
                                                />
                                            </Form.Group>
                                        </div> */}

                                        <div className="col-md-6">
                                            <Form.Group>
                                                <label htmlFor="address.country">Country</label>
                                                <select
                                                    className="form-control"
                                                    id="address.country"
                                                    onChange={this.handleChange}
                                                    value={this.state.country || "UNITED STATES"}
                                                    style={{ color: "black" }}

                                                >
                                                    <option value="United States">UNITED STATES</option>
                                                    <option value="India">INDIA</option>
                                                </select>
                                            </Form.Group>
                                        </div>
                                    </div>

                                    {/* <div className="row mb-3">
                                        <div className="col-md-6">
                                            <Form.Group>
                                                <label htmlFor="address.latitude">Latitude</label>
                                                <Form.Control
                                                    type="text"
                                                    className="h-auto"
                                                    size="lg"
                                                    id="address.latitude"
                                                    onChange={this.handleChange}
                                                />
                                            </Form.Group>
                                        </div>
                                        <div className="col-md-6">
                                            <Form.Group>
                                                <label htmlFor="address.longitude">Longitude</label>
                                                <Form.Control
                                                    type="text"
                                                    className="h-auto"
                                                    size="lg"
                                                    id="address.longitude"
                                                    onChange={this.handleChange}
                                                />
                                            </Form.Group>
                                        </div>
                                    </div> */}

                                    <p className="card-description"  style={{ fontSize: '18px', fontWeight: 'bold' }}> Administrator </p>
                                    <div className="row mb-3">
                                        <div className="col-md-6">
                                            <Form.Group>
                                                <label htmlFor="admin.firstName">
                                                    First Name <span className="required-star"></span>
                                                </label>
                                                <Form.Control
                                                    type="text"
                                                    className="h-auto"
                                                    size="lg"
                                                    id="admin.firstName"
                                                    onChange={this.handleChange}
                                                    required
                                                />
                                            </Form.Group>
                                        </div>
                                        <div className="col-md-6">
                                            <Form.Group>
                                                <label htmlFor="admin.lastName">
                                                    Last Name <span className="required-star"></span>
                                                </label>
                                                <Form.Control
                                                    type="text"
                                                    className="h-auto"
                                                    size="lg"
                                                    id="admin.lastName"
                                                    onChange={this.handleChange}
                                                    required
                                                />
                                            </Form.Group>
                                        </div>
                                    </div>
                                    <div className="row mb-3">
                                        <div className="col-md-6">
                                            <Form.Group>
                                                <label htmlFor="admin.email">
                                                    Email <span className="required-star"></span>
                                                </label>
                                                <Form.Control
                                                    type="email"
                                                    className="h-auto"
                                                    size="lg"
                                                    id="admin.email"
                                                    onChange={this.handleChange}
                                                    required
                                                />
                                            </Form.Group>
                                        </div>
                                        <div className="col-md-6">
                                            <Form.Group>
                                                <label htmlFor="admin.contactNumber">
                                                    Contact Number <span className="required-star"></span>
                                                </label>
                                                <Form.Control
                                                    type="text"
                                                    className="h-auto"
                                                    size="lg"
                                                    id="admin.contactNumber"
                                                    onChange={this.handleChange}
                                                    required
                                                />
                                            </Form.Group>
                                        </div>
                                    </div>

                                    <div className="form-check">
                                        <label className="form-check-label text-muted">
                                            <input
                                                type="checkbox"
                                                className="form-check-input"
                                                checked={accept_terms_and_conditions}
                                                name="accept_terms_and_conditions"
                                                onChange={this.onChangedCheckbox}
                                            />{" "}
                                            <i className="input-helper"></i>
                                            <Link
                                                to={{
                                                    pathname:
                                                        "https://docs.google.com/document/d/1sCg8AdNjGT50qBFt_KaGvyoVwX_wgH7KWoWsILRJnJU/edit?usp=sharing",
                                                }}
                                                target="_blank"
                                                className="text-primary"
                                            >
                                                Accept Terms & Conditions
                                            </Link>
                                        </label>
                                    </div>
                                    <div className="form-check">
                                        <label className="form-check-label text-muted">
                                            <input
                                                type="checkbox"
                                                className="form-check-input"
                                                checked={accept_privacy_policy}
                                                name="accept_privacy_policy"
                                                onChange={this.onChangedCheckbox}
                                            />{" "}
                                            <i className="input-helper"></i>
                                            <Link
                                                to={{
                                                    pathname:
                                                        "https://docs.google.com/document/d/1NeF-BzmyAIdxV4sVarYsI43DPAkoCAGyH9tIsoAHzM0/edit?usp=sharing",
                                                }}
                                                target="_blank"
                                                className="text-primary"
                                            >
                                                Accept Privacy Policy
                                            </Link>
                                        </label>
                                    </div>

                                    <div
                                        style={{
                                            marginBottom: 10,
                                            marginLeft: 90,
                                            marginRight: 90,
                                            marginTop: 10,
                                            textAlign: "center",
                                            alignItems: "center",
                                        }}
                                    >
                                        <button type="submit" className="btn btn-primary mr-2">
                                            Submit
                                        </button>
                                        <ToastContainer />
                                    </div>
                                    <div className="text-center mt-4 font-weight-light">
                                        Already have an account? &nbsp;
                                        <label
                                            className="text-primary cursor-pointer label-title"
                                            onClick={this.signinhandleClick}
                                        >
                                            Sign In
                                        </label>
                                    </div>
                                </Form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
//export default Register
export default withRouter(Register);
