import React, { Component } from "react";

class Footer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            currentYear: new Date().getFullYear()
        };
    }

    render() {
        return (
            <footer className="footer">
                <div className="container-fluid">
                    <div className="d-sm-flex justify-content-center justify-content-sm-between">
                        <span className="text-muted text-center text-sm-left d-block d-sm-inline-block">
                            Copyright © {this.state.currentYear}{" "}
                            <a href="https://generationscubed.com/" target="_blank" rel="noopener noreferrer">
                                Generations Cubed Inc
                            </a>
                            . All rights reserved.
                        </span>
                    </div>
                </div>
            </footer>
        );
    }
}

export default Footer;
