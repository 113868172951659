import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
class Sidebar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            logo: "",
        };
    }

    toggleMenuState(menuState) {
        if (this.state[menuState]) {
            this.setState({ [menuState]: false });
        } else if (Object.keys(this.state).length === 0) {
            this.setState({ [menuState]: true });
        } else {
            Object.keys(this.state).forEach((i) => {
                this.setState({ [i]: false });
            });
            this.setState({ [menuState]: true });
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.location !== prevProps.location) {
            this.onRouteChanged();
        }
    }

    onRouteChanged() {
        document.querySelector("#sidebar").classList.remove("active");
        Object.keys(this.state).forEach((i) => {
            this.setState({ [i]: false });
        });
    }

    componentDidOrgBigLogo = () => {
        if (window.sessionStorage.getItem("org_logo") === "" || window.sessionStorage.getItem("org_logo") === null) {
            return (
                <img
                    className="rounded-circle"
                    alt="/"
                    src={require("../../assets/images/gen3logowhite.svg")}
                    width="80"
                    height="80"
                    style={{ marginTop: 30 }}
                />
            );
        } else {
            return (
                <img
                    className="rounded-circle"
                    src={window.sessionStorage.getItem("org_logo")}
                    alt="/"
                    style={{ marginTop: 40, width: 70, height: 70 }}
                />
            );
        }
    };

    componentDidOrgSmallLogo = () => {
        if (window.sessionStorage.getItem("org_logo") === "" || window.sessionStorage.getItem("org_logo") === null) {
            return (
                <img
                    className="rounded-circle"
                    alt="/"
                    src={require("../../assets/images/gen3_logo_white.jpg")}
                    style={{ width: 70, height: 70 }}
                />
            );
        } else {
            return (
                <img
                    className="rounded-circle"
                    src={window.sessionStorage.getItem("org_logo")}
                    alt="/"
                    style={{ width: 40, height: 40 }}
                />
            );
        }
    };

    render() {
        return (
            <nav className="sidebar sidebar-offcanvas " id="sidebar">
                <div className="text-center sidebar-brand-wrapper d-flex align-items-center">
                    <a className="sidebar-brand brand-logo" href="index.html">
                        <div>{this.componentDidOrgBigLogo()}</div>
                        {/* <img
                            className="img-xs rounded-circle"
                            src={window.sessionStorage.getItem("org_logo")}
                            // alt="user icon"
                            style={{ marginTop: 40, height: 70, width: 70 }}
                        /> */}
                    </a>
                    <a className="sidebar-brand brand-logo-mini pt-3" href="index.html">
                        <div>{this.componentDidOrgSmallLogo()}</div>
                        {/* <img
                            className="img-xs rounded-circle"
                            src={window.sessionStorage.getItem("org_logo")}
                            // alt="user icon"
                        /> */}
                    </a>
                </div>

                <ul className="nav" style={{ marginTop: 40 }}>
                    <li className={this.isPathActive("/dashboard") ? "nav-item active" : "nav-item"}>
                        <Link className="nav-link " to="/dashboard " style={{ display: "flex", alignItems: "center" }}>
                            <i className="mdi  menu-icon "></i>
                            <i className="mdi mdi-home menu-icon "></i>
                            <span className="menu-title ">Home</span>
                        </Link>
                    </li>

                    <li className={this.isPathActive("/organization") ? "nav-item active" : "nav-item"}>
                        <Link className="nav-link" to="/organization/myorganization">
                            <i className="mdi menu-icon "></i>
                            <i className="mdi mdi-domain menu-icon"></i>
                            <span className="menu-title">My Organization</span>
                        </Link>
                    </li>

                    <li className={this.isPathActive("/orgadmins") ? "nav-item active" : "nav-item"}>
                        <Link className="nav-link" to="/orgadmins">
                            <i className="mdi  menu-icon "></i>
                            <i className="mdi mdi-cog-outline menu-icon"></i>
                            <span className="menu-title">Org Admins</span>
                        </Link>
                    </li>

                    <li className={this.isPathActive("/team") ? "nav-item active" : "nav-item"}>
                        <Link className="nav-link" to="/team/myteamlist">
                            <i className="mdi  menu-icon "></i>
                            <i className="mdi mdi-account-group menu-icon"></i>
                            <span className="menu-title">Members</span>
                        </Link>
                    </li>

                    <li className={this.isPathActive("/campaigns") ? "nav-item active" : "nav-item"}>
                        <Link className="nav-link" to="/campaigns/campaignslist">
                            <i className="mdi  menu-icon "></i>
                            <i className="mdi mdi-calendar-star menu-icon"></i>
                            <span className="menu-title">Campaigns</span>
                        </Link>
                    </li>

                    <li className={this.isPathActive("reports") ? "nav-item active" : "nav-item"}>
                        <Link className="nav-link" to="/reports/myreports">
                            <i className="mdi  menu-icon "></i>
                            <i className="mdi mdi-table-large menu-icon"></i>
                            <span className="menu-title">Reports</span>
                        </Link>
                    </li>
                </ul>

                <div>
                    <div style={{ marginLeft: 70, marginTop: 50, color: "white" }}>
                        <label>Powered by</label>
                    </div>
                    <div className="text-center sidebar-brand-wrapper d-flex align-items-center">
                        <span className="sidebar-brand brand-logo">
                            {/* <label htmlFor="shortCode">Powered by</label> */}
                            <img
                                className="rounded-circle"
                                src={require("../../assets/images/gen3_logo_white.jpg")}
                                alt="logo"
                                style={{ width: 70, height: 70, marginRight: 50 }}
                            />
                        </span>
                        <a className="sidebar-brand brand-logo-mini pt-3" href="index.html">
                            <img src={require("../../assets/images/gen3_logo_white.jpg")} 
                            alt="logo"
                            style={{ width: 40, height: 40, backgroundColor: 'rgba(255, 255, 255, 0.5)', borderRadius: '50%', padding: 0  }} />
                        </a>
                    </div>
                </div>
            </nav>
        );
    }

    isPathActive(path) {
        return this.props.location.pathname.startsWith(path);
    }

    componentDidMount() {
        this.onRouteChanged();
        // add className 'hover-open' to sidebar navitem while hover in sidebar-icon-only menu
        const body = document.querySelector("body");
        document.querySelectorAll(".sidebar .nav-item").forEach((el) => {
            el.addEventListener("mouseover", function () {
                if (body.classList.contains("sidebar-icon-only")) {
                    el.classList.add("hover-open");
                }
            });
            el.addEventListener("mouseout", function () {
                if (body.classList.contains("sidebar-icon-only")) {
                    el.classList.remove("hover-open");
                }
            });
        });
    }
}

export default withRouter(Sidebar);
