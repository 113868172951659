import firebase from "firebase";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";

var stage = "dev-api";

var user_api_url = "";
var rfh_api_url = "";
var campaign_api_url = "";
var review_api_url = "";

if (stage === "local") {
    user_api_url = "http://localhost:8081/";
    campaign_api_url = "http://localhost:8082/";
    rfh_api_url = "http://localhost:8083/";
    review_api_url = "http://localhost:8084/";
} else if (stage === "dev") {
    user_api_url = "http://54.164.240.17/user.api/";
    rfh_api_url = "http://54.164.240.17/rfh.api/";
    campaign_api_url = "http://54.164.240.17/campaign.api/";
    review_api_url = "http://54.164.240.17/review.api/";
} else if (stage === "test") {
    user_api_url = "http://54.164.240.17/user.api/";
    rfh_api_url = "http://54.164.240.17/rfh.api/";
    campaign_api_url = "http://54.164.240.17/campaign.api/";
    review_api_url = "http://54.164.240.17/review.api/";
} else if (stage === "prod") {
    user_api_url = "http://localhost:8081/";
    campaign_api_url = "http://localhost:8082/";
    rfh_api_url = "http://localhost:8083/";
    review_api_url = "http://localhost:8084/";
}

else if (stage === "dev-https") {
    user_api_url = "https://9ogb26tqs7.execute-api.us-east-1.amazonaws.com/";
    campaign_api_url = "https://rdmz426l4h.execute-api.us-east-1.amazonaws.com/";
    rfh_api_url = "https://7r23iiut2b.execute-api.us-east-1.amazonaws.com/";
    review_api_url = "https://tdy4atwkpf.execute-api.us-east-1.amazonaws.com/";
}

else if (stage === "dev-api") {
    user_api_url = "https://api.generationscubed.com/user.api/";
    rfh_api_url = "https://api.generationscubed.com/rfh.api/";
    campaign_api_url = "https://api.generationscubed.com/campaign.api/";
    review_api_url = "https://api.generationscubed.com/review.api/";
}

export const ENV = {
    module_user_url: user_api_url,
    module_rfh_url: rfh_api_url,
    module_campaign_url: campaign_api_url,
    module_review_url: review_api_url,
};

export function validateResponse(response) {
    if (response === undefined) {
        //toast.error('Unable to connect to server. try later.');
        //window.location.href = "/ServerError";
    } else {
        if (response.status === 401) {
            if (response.response.data.failure_code === "INVALID_USER") {
                window.location.href = "/";
                window.localStorage.setItem("screen", "signup");
            } else if (
                response.response.data.failure_code === "INVALID_BEARER_TOKEN" ||
                response.response.data.failure_code === "MISSING_BEARER_TOKEN"
            ) {
                toast.error("Session expired. Login again.");
                firebase
                    .auth()
                    .signOut()
                    .then(function () {
                        window.localStorage.removeItem("user");
                        window.localStorage.removeItem("screen");
                        window.location.href = "/";
                    })
                    .catch(function () {
                        console.log("Error Signed out");
                    });
            }
        } else if (response.status === 403) {
            // toast.error('User not authorized to do this action.');
            //window.location.href = "/UnAuthorized";
        } else if (response.status === 404) {
            //        toast.error('Server error occured. try later.');
            //window.location.href = "/Error404";
        } else if (response.status === 500) {
            //      toast.error(response.data.error_message);
            //window.location.href = "/Error404";
        }
    }
}

export function validateErrorResponse(errorResponse) {
    if (errorResponse.response === undefined) {
        //toast.error('Unable to connect to server. try later.');
        //window.location.href = "/ServerError";
    } else {
        if (errorResponse.response.status === 401) {
            if (errorResponse.response.data.failure_code === "INVALID_USER") {
                window.location.href = "/";
                window.localStorage.setItem("screen", "signup");
            } else if (
                errorResponse.response.data.failure_code === "INVALID_BEARER_TOKEN" ||
                errorResponse.response.data.failure_code === "MISSING_BEARER_TOKEN"
            ) {
                toast.error("Session expired. Login again.");
                firebase
                    .auth()
                    .signOut()
                    .then(function () {
                        window.localStorage.removeItem("user");
                        window.localStorage.removeItem("screen");
                        window.location.href = "/";
                    })
                    .catch(function () {
                        console.log("Error Signed out");
                    });
            }
        } else if (errorResponse.response.status === 403) {
            //   toast.error('User not authorized to do this action.');
            //window.location.href = "/UnAuthorized";
        } else if (errorResponse.response.status === 404) {
            //   toast.error('Server error occured. try later.');
            //window.location.href = "/Error404";
        } else if (errorResponse.response.status === 500) {
            //  toast.error(errorResponse.response.data.error_message);
            //window.location.href = "/Error404";
        } else if (errorResponse.response.status === 400) {
            if (errorResponse.response.data.failure_code === "USER_NOT_PART_OF_ORG") {
                toast.error("User is not a part of an organizatation", { autoClose: 15000 });
                firebase
                    .auth()
                    .signOut()
                    .then(function () {
                        window.localStorage.removeItem("user");
                        window.localStorage.removeItem("screen");
                        window.location.href = "/";
                    })
                    .catch(function () {
                        console.log("Error! Not signed out.");
                    });
            }
        }
    }
}
