import React, { Component } from "react";
import firebase from "firebase";
import StyledFirebaseAuth from "react-firebaseui/StyledFirebaseAuth";
import { getUserDetails } from "../services/OrganizationServices";
import { Form } from "react-bootstrap";
import { Link } from "react-router-dom";

firebase.initializeApp({
    apiKey: "AIzaSyBhYnpceP7RijW0b9tBBxDowsT25UiDCn4",
    authDomain: "careshare-14e65.firebaseapp.com",
    projectId: "careshare-14e65",
    storageBucket: "careshare-14e65.appspot.com",
    messagingSenderId: "961791098395",
    appId: "1:961791098395:web:4106f9fa7b12e1727542be",
    measurementId: "G-26SNWT5BHH",
});
class FirebaseLogin extends Component {
    state = { isSignedIn: false };
    uiConfig = {
        signInFlow: "popup",
        signInOptions: [
            firebase.auth.EmailAuthProvider.PROVIDER_ID,
            firebase.auth.GoogleAuthProvider.PROVIDER_ID,
            firebase.auth.FacebookAuthProvider.PROVIDER_ID,
        ],
        callbacks: {
            signInSuccess: () => false,
        },
    };
    componentDidMount = () => {
        this.userAuthentication();
    };
    userAuthentication() {
        if (window.localStorage.getItem("user") == null) {
            firebase.auth().onAuthStateChanged((user) => {
                if (user) {
                    this.setState({ isSignedIn: !!user });
                    console.log("email " + user.email);
                    console.log("name " + user.displayName);
                    this.getFireToken();
                } else {
                    console.log("No user logged in");
                }
            });
        }
    }
    getFireToken() {
        firebase
            .auth()
            .currentUser.getIdToken(/* forceRefresh */ true)
            .then(function (idToken) {
                // Send token to your backend via HTTPS
                window.localStorage.setItem("authToken", idToken);
                console.log("id Token", idToken);
                getUserDetails().then((response) => {
                    if (response.data !== undefined && response.data !== "") {
                        localStorage.setItem("loggedinuser", JSON.stringify(response.data));
                        localStorage.setItem("userId", JSON.stringify(response.data.userId));

                        try {
                            if (response.message != null) {
                                if (response.data.error_message !== "") {
                                    // $('#loginErrorDiv').show();
                                    // $('#loginError').text(response.data.error_message);
                                    // $('#loginButton').text('Login');
                                    // $('#loginButton').removeAttr('disabled');
                                }
                            } else {
                                window.localStorage.setItem("isMenus", true);
                                window.localStorage.setItem("user", "TEST");
                                window.localStorage.setItem("screen", "signup");
                                window.location.href = "/";
                            }
                        } catch (err) {
                            // $('#loginErrorDiv').show();
                            // $('#loginError').text(err.message);
                            // $('#loginButton').text('Login');
                            // $('#loginButton').removeAttr('disabled');
                        }
                    }
                });
            })
            .catch(function (error) {
                // Handle error
            });
    }
    saveUserDetails = () => {
        getUserDetails().then((response) => {
            if (response) {
                try {
                    if (response.message != null) {
                        if (response.data.error_message !== "") {
                            // $('#loginErrorDiv').show();
                            // $('#loginError').text(response.data.error_message);
                            // $('#loginButton').text('Login');
                            // $('#loginButton').removeAttr('disabled');
                        }
                    } else {
                        window.localStorage.setItem("isMenus", true);
                        window.localStorage.setItem("user", "TEST");
                    }
                } catch (err) {}
            }
        });
    };
    render() {
        return (
            <div className="App">
                <div className="d-flex align-items-center auth px-0">
                    <div className="row w-100 mx-0">
                        <div className="col-lg-4 mx-auto">
                            <div className="auth-form-light text-left py-5 px-4 px-sm-5">
                                <div className="brand-logo text-center">
                                    <img
                                        style={{ width: 100, height: 100 }}
                                        src={require("../../assets/images/gen3_logo.jpeg")}
                                        alt="logo"
                                    />
                                </div>

                                <Form className="pt-3">
                                    {this.state.isSignedIn ? (
                                        <span></span>
                                    ) : (
                                        <StyledFirebaseAuth uiConfig={this.uiConfig} firebaseAuth={firebase.auth()} />
                                    )}
                                    <div className="my-2 d-flex" style={{ justifyContent: "center" }}>
                                        <div className="form-check">
                                            <div className="text-center mt-4 font-weight-light">
                                                By using the app, you agree to
                                            </div>
                                            <Link
                                                to={{
                                                    pathname:
                                                        "https://docs.google.com/document/d/1sCg8AdNjGT50qBFt_KaGvyoVwX_wgH7KWoWsILRJnJU/edit?usp=sharing",
                                                }}
                                                target="_blank"
                                                className="text-primary"
                                                style={{ marginLeft: 20, marginRight: 5 }}
                                            >
                                                Terms of Service
                                            </Link>
                                            and
                                            <Link
                                                to={{
                                                    pathname:
                                                        "https://docs.google.com/document/d/1NeF-BzmyAIdxV4sVarYsI43DPAkoCAGyH9tIsoAHzM0/edit?usp=sharing",
                                                }}
                                                target="_blank"
                                                className="text-primary"
                                                style={{ marginLeft: 4 }}
                                            >
                                                Privacy Policy
                                            </Link>
                                        </div>
                                    </div>
                                </Form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
export default FirebaseLogin;
