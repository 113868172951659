import React, { Component } from "react";
import { Dropdown } from "react-bootstrap";
import firebase from "firebase";

import { getOrganization } from "../services/OrganizationServices";
import { Link } from "react-router-dom";
class Navbar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            logo: "",
            firstName: "",
            lastName: "",
            profilePic: "",
        };
    }

    componentDidMount() {
        const userData = JSON.parse(localStorage.getItem("loggedinuser"));
        if (userData != null) {
            this.setState({
                firstName: userData.firstName,
                lastName: userData.lastName,
            });
        }

        this.getOrganization();
    }

    componentDidUpdate = () => {
        if (
            window.sessionStorage.getItem("profileLogo") === "" ||
            window.sessionStorage.getItem("profileLogo") === null
        ) {
            return (
                <img
                    className="rounded-circle"
                    alt="/"
                    src={require("../../assets/images/user.png")}
                    width="40"
                    height="40"
                />
            );
        } else {
            return (
                <img
                    className="rounded-circle"
                    src={window.sessionStorage.getItem("profileLogo")}
                    alt="/"
                    style={{ width: 50, height: 50 }}
                />
            );
        }
    };

    getOrganization() {
        this.setState({
            loading: true,
        });

        getOrganization(window.sessionStorage.getItem("org_id")).then((response) => {
            if (response) {
                try {
                    this.setState({
                        logo: response.data.logo,
                    });
                } catch (error) {
                    this.setState({
                        loading: false,
                    });
                }
            }
        });
    }

    toggleOffcanvas() {
        document.querySelector(".sidebar-offcanvas").classList.toggle("active");
    }

    logout() {
        firebase
            .auth()
            .signOut()
            .then(function () {
                window.localStorage.removeItem("user");
                window.localStorage.removeItem("screen");
                window.location.href = "/";
                console.log("You have signed out");
            })
            .catch(function () {
                console.log("Error signing out");
            });
        localStorage.clear();
    }

    render() {
        return (
            <div>
                <header>
                    <nav className="navbar col-lg-12 col-12 p-lg-0 fixed-top d-flex flex-row">
                        <div className="navbar-menu-wrapper d-flex align-items-center justify-content-between">
                            <button
                                className="navbar-toggler navbar-toggler align-self-center"
                                type="button"
                                onClick={() => document.body.classList.toggle("sidebar-icon-only")}
                            >
                                <i className="mdi mdi-menu"></i>
                            </button>
                            <ul className="navbar-nav navbar-nav-left header-links align-self-center">
                                <li className="nav-item font-weight-semibold d-none  d-md-flex">
                                    Help : support@generationscubed.com
                                </li>
                                <li className="nav-item dropdown language-dropdown"></li>
                            </ul>
                            {/* <form className="ml-auto search-form d-none d-md-block" action="#">
                                <div className="form-group">
                                    <input type="search" className="form-control" placeholder="Search Here" />
                                </div>
                            </form> */}

                            <ul className="navbar-nav navbar-nav-right ml-lg-auto">
                                <li className="nav-item  nav-profile ">
                                    <Dropdown>
                                        <Dropdown.Toggle className="nav-link count-indicator bg-transparent ">
                                            <div>{this.componentDidUpdate()}</div>
                                            {/* <img
                                                className="img-xs rounded-circle"
                                                // src={require("../../assets/images/user.png")}
                                                src={window.sessionStorage.getItem("profileLogo")}
                                                alt="user icon"
                                                style={{ height: 50, width: 50 }}
                                            /> */}
                                        </Dropdown.Toggle>

                                        <Dropdown.Menu className="preview-list navbar-dropdown  pb-3">
                                            <Dropdown.Item
                                                className="dropdown-item p-0 preview-item d-flex align-items-center border-bottom"
                                                href="!#"
                                                onClick={(evt) => evt.preventDefault()}
                                            >
                                                <div className="py-3 px-2 d-flex align-items-center justify-content-center ">
                                                    <Link
                                                        to={{
                                                            pathname: "/organization/editprofile",
                                                        }}
                                                        onClick={() => {
                                                            window.localStorage.setItem("campaign_id", "");
                                                        }}
                                                    >
                                                        <i
                                                            className="mdi mdi-account-outline"
                                                            style={{
                                                                fontSize: 22,
                                                                color: "black",
                                                            }}
                                                        ></i>

                                                        <label style={{ color: "black" }}>
                                                            {this.state.firstName} {this.state.lastName}
                                                        </label>
                                                    </Link>
                                                </div>
                                            </Dropdown.Item>
                                            <Dropdown.Item
                                                className="dropdown-item preview-item py-3 px-2 d-flex align-items-center border-0"
                                                onClick={(evt) => evt.preventDefault()}
                                            >
                                                <Link
                                                    to={{
                                                        pathname: "/organization/editprofile",
                                                    }}
                                                    onClick={() => {
                                                        window.localStorage.setItem("campaign_id", "");
                                                    }}
                                                >
                                                    <i
                                                        className="mdi mdi-pencil"
                                                        style={{
                                                            fontSize: 22,
                                                            color: "black",
                                                        }}
                                                    ></i>

                                                    <label style={{ color: "black" }}>Edit Profile</label>
                                                </Link>
                                            </Dropdown.Item>
                                            <Dropdown.Item
                                                className="py-3 px-2 dropdown-item preview-item d-flex align-items-center border-0"
                                                onClick={() => {
                                                    this.logout();
                                                }}
                                            >
                                                <i
                                                    className="mdi mdi-logout"
                                                    style={{
                                                        fontSize: 22,
                                                        color: "black",
                                                    }}
                                                ></i>
                                                <span className="menu-style">Sign Out</span>
                                            </Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </li>
                            </ul>
                            <button
                                className="navbar-toggler navbar-toggler-right d-lg-none align-self-center"
                                type="button"
                                onClick={this.toggleOffcanvas}
                            >
                                <span className="mdi mdi-menu"></span>
                            </button>
                        </div>
                    </nav>
                </header>
            </div>
        );
    }
}

export default Navbar;
